import './loader.scss';

const Loader = (
    { className }: { className?: string }
) =>
    <div className={`${!!className ? `${className} ` : ''}p-loader-wrapper`} >
        <div className='p-loader' />
    </div>

export default Loader;

