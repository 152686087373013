import React, {lazy, Suspense} from 'react';
import { createRoot } from 'react-dom/client';

// import reportWebVitals from './reportWebVitals';

import Loader from "factor-lib/Loader.tsx";

const MainRouterAndQueryWrapperLazy = lazy(() => import('./MainRouterAndQueryWrapper'));

// !!!  Need to be the last import !!!, otherwise link style breaks
import './index.scss';

createRoot(document.getElementById('root')!)
    .render(
        <React.StrictMode>
            <Suspense fallback={
                // TODO : a better layout ?
                <Loader/>
            }>
                <MainRouterAndQueryWrapperLazy />
            </Suspense>
        </React.StrictMode>
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
